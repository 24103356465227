import * as React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faTags } from '@fortawesome/pro-light-svg-icons'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import * as styles from './Post.module.scss'
import { Layout } from '../components/layout'
import { BlogPostQueryQuery } from '../../graphql-types'

interface PostProps {
  data: BlogPostQueryQuery
}
export const Post: React.FC<PostProps> = (props) => {
  console.log(props)
  const {
    data: {
      post,
      site: {
        siteMetadata: { title },
      },
    },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${post.title}`}</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <h1>{post.title}</h1>
      <div className={styles.metadata}>
        <div>
          <FontAwesomeIcon icon={faCalendarAlt} height={16} /> {post.date}
        </div>
        {post.tags && post.tags.length !== 0 && (
          <div className={styles.tags}>
            <FontAwesomeIcon icon={faTags} height={16} />
            {post.tags.map((tag) => (
              <Link key={tag} to={`/blog/tags/${tag.toLowerCase()}`}>
                {tag}
              </Link>
            ))}
          </div>
        )}
      </div>
      <MDXRenderer>{post.body}</MDXRenderer>
    </Layout>
  )
}

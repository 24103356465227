import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Post } from '../components/Post'
import { BlogPostQueryQuery } from '../../graphql-types'

export default ({ data }: PageProps<BlogPostQueryQuery>) => (
  <Post data={data} />
)

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    post: blogPost(id: { eq: $id }) {
      id
      title
      date(formatString: "MMMM DD, YYYY")
      excerpt
      body
      tags
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
